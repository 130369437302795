import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"

import Head from "../components/head"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Features from "../components/features"
import Explainer from "../components/explainer"
import Plan from "../components/plan"
import SimpleLogos from "../components/simplelogos"
import FAQ from "../components/faq"
import Pricing from "../components/pricing"
import Tasks from "../components/tasks"
import Testimonials from "../components/testimonials"
import CTA from "../components/cta"
import { useStaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"

import partnerLogo from "../images/shopify-partner-logo.png"
import partnerLogos from "../images/partner-logos@4x.png"

export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "unlimited-hero@4x.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    findProblemsImage: file(relativePath: { eq: "find-problems@4x.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    greatJobImage: file(relativePath: { eq: "great-job@4x.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    taskCompletedImage: file(relativePath: { eq: "task-completed@4x.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const UnlimitedPage = ({data}) => (
  <Layout>
    <Head/>
    <SEO title="Home" />
    <Hero
      headline={(<span>Ready for <u>unlimited</u> Shopify tech help?</span>)}
      dek="You’re building a business. Technical issues shouldn’t throttle your growth. For one monthly fee, we'll handle all your shopify tech, and you can focus on what matters."
      hero={
        <Img fluid={data.placeholderImage.childImageSharp.fluid} />
      }
      cta="Try for free"
      ctaLink="javascript:document.getElementById('launchFreeTrial').click();"
    />

    <Features
      logo={partnerLogo}
      nuts={[
        {
          icon: "ti-timer",
          heading: "Improve store performance",
          text: "Watch your store performance soar, along with your conversion rates.",
        },
        {
          icon: "ti-star",
          heading: "Nail the fit and finish",
          text: "Wipe out design flaws, theme bugs, and tweaks.",
        },
        {
          icon: "ti-heart",
          heading: "Customize with confidence",
          text: "Tackle complicated app installations and configurations.",
        }
      ]}
    />
    <SimpleLogos
      logos={partnerLogos}
    />
    <Explainer
    headline={(<span>We're your <u>secret</u> weapon</span>)}
      para={(<>
              <p className="title-subtitle title-subtitle-dek mx-auto">
                  You don’t have to be a superhero to run your Shopify business,
                  but it often feels like you do.
              </p>
              <p className="title-subtitle mx-auto">
                  You’re battling app vendors with flakey integrations, fighting
                  through unclear support channels, all while the most
                  unpredictable market throws new complications your way daily.
                  Batman had Alfred. You have us. We’ll help propel you to
                  victory, each and every time. We are your virtual technical
                  support team, ready to hop in wherever and however you need
                  us.
              </p>
              <p className="title-subtitle mx-auto">
                  Our founders forged careers running tech teams at the biggest
                  tech companies, including Amazon, Google, and Microsoft. We’re
                  a dependable, responsive, and enterprise-ready technical desk
                  for your Shopify business. Let us help you be the business
                  hero you already are.
              </p>
            </>)}
      cards={[
        {
          heading:"Easy communication",
          icon: "ti-comments",
          image: <Img fluid={data.findProblemsImage.childImageSharp.fluid} />,
          text:"You can reach us via email, text, phone, and chat; whatever’s convenient for you. We promise a response within an hour, too."
        },
        {
          heading:"Blazing fast support",
          icon: "ti-pulse",
          image: <Img fluid={data.taskCompletedImage.childImageSharp.fluid} />,
          text:"You don’t have time to wait. Who does? We get through your tasks, sometimes in a single day. Our speed never comes at the expense of quality, though."
        },
        {
          heading:"All star experts",
          icon: "ti-crown",
          image: <Img fluid={data.greatJobImage.childImageSharp.fluid} />,
          text:"You deserve a tech team as talented as your own. Our knowledgeable crew are masters of the Shopify system and can tackle your issues, large and small."
        }
      ]}
    />
    <Plan
      headline={(<span>Take us for a <u>spin</u></span>)}
      ctaLink="javascript:document.getElementById('launchFreeTrial').click();"
      steps={[
        {heading:'Create your tasks', text:'Send us all your lists and asks. If you can dream it, we can do it.'},
        {heading:'Approve the solution', text:'We assess and come up with a game plan for your approval.'},
        {heading:'Watch us work', text:'Watch your task list disappear with real time updates on our progress.'}
      ]}
    />
    <Explainer
      headline={(<span>Big features, bigger <u>benefits</u></span>)}
      sections={[
        {
          heading:"Access experts quickly without extra overhead ",
          image: <Img fluid={data.findProblemsImage.childImageSharp.fluid} />,
          text:"It’s costly to build or subcontract a team of top engineers. Because we’re on-demand and affordable, we’re the unicorn you always wanted."
        },
        {
          heading:"Get live status on your tasks",
          image: <Img fluid={data.taskCompletedImage.childImageSharp.fluid} />,
          text:"With our agile tracking system, spend less time talking about work being done and literally watch it be completed."
        },
        {
          heading:"Do more with less resources",
          image: <Img fluid={data.greatJobImage.childImageSharp.fluid} />,
          text:"You’re measuring the return on every investment. Our work will move your needle and help you achieve your vision faster."
        }
      ]}
    />
    <Testimonials/>
    <Tasks
      icon="ti-check-box"
      headline="What you can get done"
      dek="Check out these recently completed tasks for happy store owners."
      tasks={[
        {heading:'Add and style gifting options to the checkout', type:'support', time:'2 days', icon:'ti-support', csat:9},
        {heading:'Add description text to the collection pages', type:'support', icon:'ti-support', time:'1 days', csat:10},
        {heading:'Add zoomable images to the theme', type:'support', icon:'ti-support',time:'3 days', csat:8},
        {heading:'Look into lighthouse performance score', type:'investigate', icon:'ti-light-bulb', time:'2 days', csat:9},
        {heading:'Investigate why sales spiked on Friday', type:'report', icon:'ti-write', time:'1 days', csat:10},
        {heading:'Fix spacing below the image carousel', type:'support', icon:'ti-support', time:'1 days', csat:9},
        {heading:'Fix an error with in the theme', type:'support', time:'1 days', icon:'ti-support', csat:10},
        {heading:'Solve the issues with google shopping integration', type:'support', time:'3 days', icon:'ti-support', csat:9},
      ]}
    />
    <FAQ/>
    <CTA
      heading="Take us for a spin, create a free task..."
      cta="Try for free"
      ctaLink="javascript:document.getElementById('launchFreeTrial').click();"
    />
  </Layout>
)

export default UnlimitedPage
